import * as React from "react";
import PropTypes from "prop-types";
import SimpleBar from "./SimpleBar";
import DoubleBar from "./DoubleBar";
import { Grid, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import IssuesList from "./IssuesList";
import SysRoles from "../../services/SysRoles";
import styled from "styled-components";
// import moment from 'moment'
import { IoCaretDown, IoCaretUp } from "react-icons/io5";

import {
  StatsContainer,
  SectionTitle,
  BarContainer,
  MeasuresContainer,
} from "./StyledComponents";
import ExtraPotentialRowRevenue from "./ExtraPotentialRowRevenue";
import ExtraPotentialRowKwh from "./ExtraPotentialRowKwh";
import UserRoles from "../../services/UserRoles";
import PowerChart from "./PowerChart";
import CalendarModal from "./CalendarModal";
import labelOptions from "../../styles/index";

const YieldAndEPINumber = styled.span`
  // color: green;
  font-size: 2em;
`;
const MeasureText = styled.span`
  font-size: 14px;
`;

const SuccessNumber = styled.span`
  // color: green;
  font-size: 1.5em;
`;
const FailNumber = styled.span`
  // color: red;
  font-size: 1.5em;
`;
const NeutralNumber = styled.span`
  // color: orange;
  font-size: 1.5em;
`;

const StatTitle = styled.div`
  display: inline-block;
  margin-bottom: 5px;
`;

const GraphRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
`;

const NA_VALUE = "N/A";

// returns a fixed decimal numeric value, or N/A value
const getValue = (value, decimal = 1) => {
  return value === NA_VALUE ? NA_VALUE : Number(value).toFixed(decimal);
};

// returns a measured text with relevant units, or false
const getUnits = (value, units) => {
  const toRender = value === NA_VALUE ? "" : units;
  return <MeasureText>{toRender}</MeasureText>;
};

// formats a percent number with interactive color options and decimal truncating
const formatPercentNumber = (value, interactiveColor, decimal = 1) => {
  value = getValue(value); // made to keep colors consistent when fractional part does not show
  const element = (
    <>
      {value === NA_VALUE
        ? NA_VALUE
        : value > 0
        ? `+${Number(value).toFixed(decimal)}`
        : Number(value).toFixed(decimal)}
      {getUnits(value, "%")}
    </>
  );
  if (!interactiveColor || value > 0 || value === NA_VALUE) {
    return <SuccessNumber dir="ltr">{element}</SuccessNumber>;
  } else if (value < 0) {
    return <FailNumber dir="ltr">{element}</FailNumber>;
  }
  return <NeutralNumber dir="ltr">{element}</NeutralNumber>;
};

class SystemStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOpen: props.preferences.powerGraphExpandedByDefault.value,
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.event({
        category: "User",
        action: "Entered daily tab",
      });
    }
  }

  // componentDidUpdate(prevProps) {
  //   const dailyPower = this.props.sysStatus.daily_sys_power;
  //   const prevDailyPower = prevProps.sysStatus.daily_sys_power;
  //   if (
  //     ((!prevDailyPower && dailyPower) || (prevDailyPower && !dailyPower)) &&
  //     prevDailyPower !== dailyPower
  //   ) {
  //     this.setState({ chartOpen: false });
  //   }
  // }

  loadDailyForSystem = (sysId, date, disabled = false) => {
    if (!disabled) {
      return this.props.loadDailyInsights(sysId, date);
    }
  };

  renderDailyArrows = () => {
    const dateFormat = "DD/MM/YYYY";
    return (
      <CalendarModal
        sysId={this.props.sysInfo.id}
        currentDateString={this.props.sysStatus.date}
        dateFormat={dateFormat}
        loadDailyForSystem={this.loadDailyForSystem}
      />
    );
  };

  renderChartButton = () => {
    const ChartButton = this.state.chartOpen ? IoCaretUp : IoCaretDown;
    return (
      <ChartButton
        style={{ cursor: "pointer", marginLeft: "1em" }}
        onClick={() =>
          this.setState((state) => ({ chartOpen: !state.chartOpen }))
        }
      />
    );
  };

  render() {
    const props = this.props;
    const { t, i18n, intactnessColor } = props;
    const direction = i18n.dir();
    const healthPct = props.sysStatus.syshealthpct
      ? Math.min(100, props.sysStatus.syshealthpct)
      : "";
    const healthPctUnit = healthPct === "" ? "" : "%";
    const viewRole = props.userRole.appRole;
    const intactnessStyle = labelOptions.dailyIntactnessColorNewStyle
      ? { color: intactnessColor }
      : {};
    return (
      <div>
        <StatsContainer>
          {this.renderDailyArrows()}
          <Row>
            <Col xs={12} sm={4}>
              <SectionTitle>{t("Performance")}</SectionTitle>
            </Col>
            <Col xs={12} sm={8}>
              <BarContainer>
                <SimpleBar
                  max={100}
                  part={parseFloat(props.sysStatus.syshealthpct, 10)}
                />
              </BarContainer>
              <Row
                style={{
                  marginBottom: "20px",
                  marginRight: direction === "ltr" ? "20%" : "0%",
                  marginLeft: direction === "rtl" ? "20%" : "0%",
                }}
              >
                <Col xs={12} sm={6}>
                  {t(labelOptions.intactnessKey)}:
                  <YieldAndEPINumber style={intactnessStyle}>
                    {`${healthPct}`}
                    <MeasureText>{healthPctUnit}</MeasureText>
                  </YieldAndEPINumber>
                </Col>
                <Col xs={12} sm={6}>
                  {t("Yield")}:
                  <YieldAndEPINumber>
                    {props.sysStatus.denoutput
                      ? `${parseFloat(props.sysStatus.denoutput, 10)}`
                      : "N/A"}
                    <MeasureText>kWh</MeasureText>
                  </YieldAndEPINumber>
                </Col>
                <Col>{this.renderChartButton()}</Col>
              </Row>
            </Col>
          </Row>
          <GraphRow>
            <Col xs={12} sm={8}>
              <PowerChart
                dataPoints={props.sysStatus.daily_sys_power}
                opened={this.state.chartOpen}
                primaryAxisIsOnLeft={
                  this.props.preferences.powerGraphPrimaryAxisIsOnLeft.value
                }
              />
            </Col>
          </GraphRow>
          <Row>
            <Col xs={12} sm={4}>
              <SectionTitle>{t("Regional Rate")}</SectionTitle>
            </Col>
            <Col xs={12} sm={8}>
              <BarContainer>
                <DoubleBar
                  val={Math.round(props.sysStatus.regionrating)}
                  max={100}
                />
              </BarContainer>
              <MeasuresContainer />
            </Col>
          </Row>

          {this.props.sysStatus.dailyratio && (
            <Row>
              <Col xs={12} sm={4}>
                <SectionTitle>{t("Output vs expected ratio")}</SectionTitle>
              </Col>
              <Col xs={12} sm={4}>
                <StatTitle>{t("Last 24h")}&nbsp;&nbsp;</StatTitle>
                {formatPercentNumber(this.props.sysStatus.dailyratio, true)}
              </Col>
            </Row>
          )}

          {viewRole === SysRoles.operator ? (
            <ExtraPotentialRowRevenue
              annualRaw={props.sysStatus.yearloss}
              dailyRaw={props.sysStatus.drevloss}
              currencyCode={props.sysInfo.currencyCode || "ILS"}
            />
          ) : (
            <ExtraPotentialRowKwh
              annualRaw={props.sysStatus.yepotential}
              dailyRaw={props.sysStatus.depotential}
            />
          )}
        </StatsContainer>
        <Grid>
          <Row>
            <Col xs={12} sm={4}>
              <SectionTitle>{t("Messages for last 24h")}</SectionTitle>
            </Col>
            <Col xs={12} sm={8}>
              <IssuesList
                sysStatus={this.props.sysStatus}
                sysInfo={this.props.sysInfo}
                isSubscribed={this.props.isSubscribed}
                userRole={this.props.userRole.name}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

SystemStatus.propTypes = {
  sysStatus: PropTypes.object, // status data to display
  sysInfo: PropTypes.object, // static system info
  isSubscribed: PropTypes.bool,
  userRole: PropTypes.object,
  loadDailyInsights: PropTypes.func,
};
SystemStatus.defaultProps = {
  sysStatus: null,
  sysInfo: null,
  isSubscribed: false,
  userRole: UserRoles.demo,
};

export default withTranslation()(SystemStatus);
