import * as React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { handleBarWidth } from "../../services/systemsArrayHelper";
import { SectionTitle } from "../SystemDailyStatus/StyledComponents";

import labelOptions from "../../styles/index";

const FORMATTED_PERCENT_NUMBER_BOUNDS = {
  upper: 0,
  lower: -2,
};

const AnnualDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 30px 10px 30px;
`;
const YearSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
`;
const YearArrow = styled.span`
  ${(props) =>
    props.isDisabled
      ? ` margin-left: 10px;
    margin-right: 10px;
    cursor: not-allowed;
    color: #969696;
  `
      : ` margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    color: #000;
  `}
`;
const YearName = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  color: #000;
`;
const EPIAndYieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Bar = styled.div`
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  margin-bottom: 20px;
`;
const SuccesBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: green;
  float: left;
  height: 10px;
  border-radius: ${(props) => (props.width === 100 ? "2em" : "2em 0 0 2em")};
`;
const FailBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: ${(props) => (props.width === 100 ? "#aaa" : "red")};
  float: left;
  height: 10px;
  border-radius: ${(props) => (props.width === 100 ? "2em" : "0 2em 2em 0")};
`;
const MeasureText = styled.span`
  font-size: 14px;
`;
const BlackNumber = styled.span`
  font-size: 1.5em;
`;
const SuccessNumber = styled.span`
  color: green;
  font-size: 1.5em;
`;
const MinorFailNumber = styled.span`
  color: #e09926;
  font-size: 1.5em;
`;
const FailNumber = styled.span`
  color: red;
  font-size: 1.5em;
`;
const NeutralNumber = styled.span`
  color: orange;
  font-size: 1.5em;
`;
const YieldAndEPINumber = styled.span`
  // color: green;
  font-size: 2em;
`;
const InfoField = styled.div`
  font-size: 14px;
  width: 100%;
  height: auto;
`;

const changeYearEnum = {
  first: "first",
  previous: "previous",
  next: "next",
  last: "last",
};

// returns a fixed decimal numeric value, or N/A value
const getValue = (value, decimal = 1) => {
  const numValue = Number.parseFloat(value);
  return Number.isNaN(numValue) ? "" : numValue.toFixed(decimal);
};

// returns a measured text with relevant units, or false
const getUnits = (value, units) => {
  const numValue = Number.parseFloat(value);
  const toRender = Number.isNaN(numValue) ? "" : units;
  return <MeasureText>{toRender}</MeasureText>;
};

// formats a percent number with interactive color options and decimal truncating
const formatPercentNumber = (
  rawValue,
  interactiveColor,
  decimal = 1,
  shouldUseFailColor = true
) => {
  const { upper: upperBound, lower: lowerBound } =
    FORMATTED_PERCENT_NUMBER_BOUNDS;
  const value = getValue(rawValue, decimal); // made to keep colors consistent when fractional part does not show
  const valueNumber = Number.parseFloat(value);
  const element = (
    <>
      {!Number.isNaN(valueNumber) && valueNumber > upperBound
        ? `+${value}`
        : value}
      {getUnits(value, "%")}
    </>
  );
  if (!interactiveColor || value > upperBound) {
    return <SuccessNumber dir="ltr">{element}</SuccessNumber>;
  } else if (value < upperBound && shouldUseFailColor) {
    if (value > lowerBound) {
      return <MinorFailNumber dir="ltr">{element}</MinorFailNumber>;
    }
    return <FailNumber dir="ltr">{element}</FailNumber>;
  }
  return <NeutralNumber dir="ltr">{element}</NeutralNumber>;
};

class SystemMonthlyStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenYearIndex: null,
    };
  }

  setLastYearIndex() {
    const sysStatus = this.props.sysStatus;
    if (Array.isArray(sysStatus) && sysStatus.length) {
      const index = sysStatus.length - 1;
      this.setState({ chosenYearIndex: index });
    } else {
      this.setState({ chosenYearIndex: null });
    }
  }

  componentDidMount() {
    this.setLastYearIndex();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sysStatus !== this.props.sysStatus) {
      this.setLastYearIndex();
    }
  }

  handleChangeYear(changeTo) {
    if (this.state.chosenYearIndex === null) {
      return;
    }

    this.setState((state, props) => {
      const lastIdx = props.sysStatus.length - 1;
      const currentIdx = state.chosenYearIndex;
      let nextIdx;
      if (changeTo === changeYearEnum.next && currentIdx < lastIdx) {
        nextIdx = currentIdx + 1;
      } else if (changeTo === changeYearEnum.previous && currentIdx > 0) {
        nextIdx = currentIdx - 1;
      } else if (changeTo === changeYearEnum.first) {
        nextIdx = 0;
      } else if (changeTo === changeYearEnum.last) {
        nextIdx = lastIdx;
      } else {
        nextIdx = currentIdx;
      }

      return { chosenYearIndex: nextIdx };
    });
  }

  getCurrentStatus() {
    const { chosenYearIndex } = this.state;
    if (chosenYearIndex === null) {
      return null;
    }

    return {
      ...this.props.sysStatus[chosenYearIndex],
    };
  }

  calcNormalizedYield = (sysPower, total, defaultValue = NaN) => {
    const value = Number.parseFloat(total) / Number.parseFloat(sysPower);
    if (!Number.isFinite(value)) {
      return defaultValue;
    }
    return value;
  };

  renderYearPicker() {
    const { year } = this.getCurrentStatus();
    const { sysStatus } = this.props;
    const { chosenYearIndex } = this.state;
    const disablePrev = chosenYearIndex === 0;
    const disableNext = chosenYearIndex === sysStatus.length - 1;
    return (
      <YearSwitcher>
        <YearArrow
          isDisabled={disablePrev}
          onClick={() => this.handleChangeYear(changeYearEnum.first)}
        >
          {"<<"}
        </YearArrow>
        <YearArrow
          isDisabled={disablePrev}
          onClick={() => this.handleChangeYear(changeYearEnum.previous)}
        >
          {"<"}
        </YearArrow>
        <YearName>{year}</YearName>
        <YearArrow
          isDisabled={disableNext}
          onClick={() => this.handleChangeYear(changeYearEnum.next)}
        >
          {">"}
        </YearArrow>
        <YearArrow
          isDisabled={disableNext}
          onClick={() => this.handleChangeYear(changeYearEnum.last)}
        >
          {">>"}
        </YearArrow>
      </YearSwitcher>
    );
  }

  render() {
    const { t, sysInfo } = this.props;
    const { chosenYearIndex } = this.state;
    if (chosenYearIndex === null) {
      return (
        <AnnualDataContainer>
          <div>{t("No status data found")}</div>
        </AnnualDataContainer>
      );
    }
    const { intact, total, yield_ratio, uptime, uptime_ratio } =
      this.getCurrentStatus();
    const normalizedTotal = this.calcNormalizedYield(sysInfo.power, total);

    return (
      <AnnualDataContainer>
        {this.renderYearPicker()}
        <EPIAndYieldContainer>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={12} sm={4}>
              <SectionTitle>{t("Annual Performance")}</SectionTitle>
            </Col>
            <Col xs={12} sm={8}>
              <Bar>
                <SuccesBar width={handleBarWidth(intact)} />
                <FailBar width={100 - handleBarWidth(intact)} />
              </Bar>
              <Row
                style={{
                  marginBottom: "20px",
                  marginRight: this.props.i18n.dir() === "ltr" ? "20%" : "0%",
                  marginLeft: this.props.i18n.dir() === "rtl" ? "20%" : "0%",
                }}
              >
                <Col xs={12} sm={12} lg={4}>
                  {t("Yield")}:
                  <YieldAndEPINumber>
                    {getValue(total)}
                    {getUnits(total, "kWh")}
                  </YieldAndEPINumber>
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  {t(labelOptions.intactnessKey)}:
                  <YieldAndEPINumber>
                    {getValue(intact)}
                    {getUnits(intact, "%")}
                  </YieldAndEPINumber>
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  {t("Uptime")}:
                  <YieldAndEPINumber>
                    {getValue(uptime)}
                    {getUnits(uptime, "%")}
                  </YieldAndEPINumber>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px", marginLeft: 0, marginRight: 0 }}>
            {/* <InfoField>
              <span>
                {t("Average regional rating")}:{" "}
                {formatPercentNumber(ratingField, true, 1, false)}
              </span>
            </InfoField> */}
            <InfoField>
              <span>
                {t("Normalized annual yield")}:{" "}
                <BlackNumber>
                  {getValue(normalizedTotal)}
                  {getUnits(normalizedTotal, "kWh / kW")}
                </BlackNumber>
              </span>
            </InfoField>
            <InfoField>
              <span>
                {t("Since the beginning of")}:{" "}
                <BlackNumber>
                  {getValue(total)}
                  {getUnits(total, "kWh")}
                </BlackNumber>
              </span>
            </InfoField>
            {!yield_ratio ? (
              false
            ) : (
              <InfoField>
                <span>
                  {t("Output vs expected ratio")}:{" "}
                  {formatPercentNumber(yield_ratio, true)}
                </span>
              </InfoField>
            )}
            <InfoField>
              <span>
                {t("Uptime vs expected ratio")}:{" "}
                {formatPercentNumber(uptime_ratio, true)}
              </span>
            </InfoField>
          </Row>
        </EPIAndYieldContainer>
      </AnnualDataContainer>
    );
  }
}

export default withTranslation()(SystemMonthlyStatus);
