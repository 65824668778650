import React from "react";
import { Row, Col } from "react-bootstrap";

import { SectionTitle, StatTitle } from "./StyledComponents";
import SingleMeasure from "./SingleMeasure";
import { withTranslation } from "react-i18next";

function ExtraPotentialRowRevenue(props) {
  const { annualRaw, dailyRaw, t, currencyCode: units } = props;
  // const units = "ILS";

  const dailyValue = Math.round(parseInt(dailyRaw, 10));
  const annualValue = Math.round(parseInt(annualRaw, 10));
  const daily = Number.isNaN(dailyValue) ? "" : dailyValue;
  const annual = Number.isNaN(annualValue) ? "" : annualValue;
  // if (isNaN(annual) && isNaN(daily)) {
  //   return null;
  // }
  return (
    <Row>
      <Col xs={12} sm={4}>
        <SectionTitle>{t("Potential extra revenue")}</SectionTitle>
      </Col>
      <Col xs={12} sm={4}>
        <StatTitle>{t("Annual")}</StatTitle>
        <SingleMeasure
          value={`${annual}`}
          units={units}
          alwaysBlackMeasure={true}
        />
      </Col>
      <Col xs={12} sm={4}>
        <StatTitle>{t("Last 24h")}</StatTitle>
        <SingleMeasure
          value={`${daily}`}
          units={units}
          alwaysBlackMeasure={true}
        />
      </Col>
    </Row>
  );
}

export default withTranslation()(ExtraPotentialRowRevenue);
