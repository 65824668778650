import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrap = styled.div`
  display: inline-block;
  margin: 0 0.5em;
`;
const Inner = styled.div`
  display: inline-block;
`;
const Units = styled.span`
  font-size: 50%;
  margin-left: -0.5em;
`;
const Plus = styled.span`
  color: green;
  font-size: 1.5em;
`;
const Minus = styled.span`
  color: red;
  font-size: 1.5em;
`;

const Neutral = styled.span`
  font-size: 1.5em;
`;

const SingleMeasure = (props) => {
  const alwaysBlackMeasure = props.alwaysBlackMeasure || false;
  const numericValue = Number.parseInt(props.value, 10);
  const formattedVal = Number.isFinite(numericValue) ? numericValue : "";
  const units = formattedVal === "" ? "" : props.units;
  const displayVal = (
    <>
      {formattedVal} <Units>{units}</Units>
    </>
  );
  return (
    <Wrap dir="ltr">
      <Inner>
        {alwaysBlackMeasure ? (
          <Neutral>{displayVal}</Neutral>
        ) : numericValue > 0 ? (
          <Plus>
            {formattedVal} <Units>{units}</Units>
          </Plus>
        ) : (
          <Minus>
            {formattedVal} <Units>{units}</Units>
          </Minus>
        )}
      </Inner>
    </Wrap>
  );
};

SingleMeasure.propTypes = {
  value: PropTypes.string,
  units: PropTypes.string,
};
SingleMeasure.defaultProps = {
  value: "37",
  units: "m2",
};

export default SingleMeasure;
