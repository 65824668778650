import styled from "styled-components";
import { Panel } from "react-bootstrap";

const ServicesLink = styled.a`
  cursor: pointer;

  &:before {
    content: "»";
    margin: 0 0.4em;
    display: inline-block;
  }
`;

const dailyFooterOptions = {
  exclude: () => null,
  includeServicesAddSystem: (props) => {
    return props.isSubscribed ? (
      props.hasIssues && (
        <ServicesLink
          key="index"
          href={process.env.REACT_APP_LABEL_SERVICES_INDEX_URL}
          target="_blank"
        >
          {props.goToServiceString}
        </ServicesLink>
      )
    ) : (
      <ServicesLink
        key="index"
        href={process.env.REACT_APP_LABEL_ADD_SYSTEM_URL}
        target="_blank"
      >
        {props.subscribeString}
      </ServicesLink>
    );
  },
};

const systemTypeLinkOptions = {
  noLink: (props) => {
    return <span>{props.children}</span>;
  },
  makerPortal: (props) => {
    const MAKE_PORTALS = {
      sma: "https://sunnyportal.com",
      solaredge: "https://monitoring.solaredge.com/solaredge-web/p/login",
      fronius: "https://www.solarweb.com/Account/ExternalLogin",
      fusion: "https://eu5.fusionsolar.huawei.com",
    };
    if (MAKE_PORTALS[props.info.make]) {
      return (
        <a
          href={props.info.supportUrl || MAKE_PORTALS[props.info.make]}
          referrerPolicy="no-referrer"
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      );
    }
    return <span>{props.children}</span>;
  },
};

const styledPanelOptions = {
  noStyle: Panel,
  upperLowerBorderColor: styled(Panel)`
    border-color: ${(props) => props.border};
    border-style: ${(props) =>
      props.direction === "left"
        ? "solid solid solid none"
        : "solid none solid solid"};
  `,
};

const DEFAULTS = {
  labelTextColor: "#a4dbf7",
  DailyFooter: dailyFooterOptions.exclude,
  SystemTypeSpan: systemTypeLinkOptions.noLink,
  StyledPanel: styledPanelOptions.noStyle,
};

function createExportedStyle() {
  const exported = {
    colors: {
      labelTextColor:
        process.env.REACT_APP_LABEL_TEXT_COLOR || DEFAULTS.labelTextColor,
    },
    IssuesList: {
      DailyFooter:
        dailyFooterOptions[process.env.REACT_APP_LABEL_DAILY_FOOTER] ||
        DEFAULTS.DailyFooter,
    },
    SystemStatusPanel: {
      SystemTypeSpan:
        systemTypeLinkOptions[process.env.REACT_APP_LABEL_SYSTEM_TYPE_LINK] ||
        DEFAULTS.SystemTypeSpan,
      StyledPanel:
        styledPanelOptions[process.env.REACT_APP_LABEL_STYLED_PANEL] ||
        DEFAULTS.StyledPanel,
      panelHeadingPercentage: {
        small: [process.env.REACT_APP_LABEL_PANEL_HEADING_SMALL] || "2%",
        large: [[process.env.REACT_APP_LABEL_PANEL_HEADING_LARGE]] || "1%",
      },
    },
    allowAnyoneToDownloadReport:
      process.env.REACT_APP_LABEL_ANYONE_CAN_DOWNLOAD !== "false"
        ? true
        : false,
    intactnessKey:
      process.env.REACT_APP_LABEL_INTACTNESS_TRANSLATION_KEY || "Intactness",
    dailyIntactnessColorNewStyle:
      process.env.REACT_APP_LABEL_INTACTNESS_NEW_COLOR !== "false"
        ? true
        : false,
  };
  return exported;
}

export default createExportedStyle();
