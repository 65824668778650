import React, { useState } from "react";
import PropTypes from "prop-types";
import { Panel, Tab, Tabs, Row, Col } from "react-bootstrap";

import styled from "styled-components";

import { withTranslation } from "react-i18next";
import SystemDailyStatus from "../SystemDailyStatus";
import SystemAnnualStatus from "../SystemAnnualStatus";
import SystemMonthlyStatus from "../SystemMonthlyStatus";
import SystemSingleAnnualStatus from "../SystemSingleAnnualStatus";
import severityColors from "../../services/severityColors";

import labelStyles from "../../styles/index";
const {
  intactnessKey: INTACTNESS_KEY,
  SystemStatusPanel: { SystemTypeSpan, StyledPanel, panelHeadingPercentage },
} = labelStyles;

const PTitle = styled.h3`
  display: inline-block;
  margin: 0 0.5em 0 0;
  font-size: 1em;
  .rtl & {
    margin: 0 0 0 0.5em;
  }
`;
const STab = styled(Tab)`
  padding: 15px;
`;
const BigText = styled.span`
  font-size: 20px;
  font-weight: 600;
`;
const NoBaldBigText = styled.span`
  font-size: 20px;
`;
const UpdateDateContainer = styled.div`
  padding: 0px 15px 17px 15px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  margin-left: -15px;
  margin-right: -15px;
  text-align: unset;
  display: flex;
  justify-content: space-between;
`;
const UpdateDateContainerSubsystem = styled.div`
  padding: 0px 15px 17px 15px;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: unset;
  display: flex;
  justify-content: space-between;
`;

const Circle = styled.div`
  background: transparent;
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
`;

const ColTextEnd = styled(Col)`
  @media (max-width: 768px) {
    text-align: end;
  }
`;

const HeadingRowSmallScreen = styled(Row)`
  margin-${(props) => props.dir}: 0.08em;
`;

const PanelHeading = styled(Panel.Heading)`
  background: linear-gradient(
    to ${(props) => props.dir},
    ${(props) => props.backgroundcolor} ${(props) => props.percentage},
    white ${(props) => props.percentage}
  );
`;

const systemBackgroundColor = (systemStatus, isParent, isFrozen) => {
  // in case of an empty day and not a parent sys, return same data as if no comm
  if ((systemStatus.emptyDay && !isParent) || isFrozen) {
    return severityColors.commColor;
  }
  const systemSeverity = +systemStatus.severity || 0;
  const systemColor =
    systemSeverity === -1
      ? severityColors.inactiveColor
      : systemSeverity === 0
      ? severityColors.successColor
      : systemSeverity === 1
      ? severityColors.primaryColor
      : systemSeverity === 1.5
      ? severityColors.lowSeverityColor
      : systemSeverity === 2
      ? severityColors.warningColor
      : systemSeverity === 2.5
      ? severityColors.commColor
      : systemSeverity === 3
      ? severityColors.dangerColor
      : severityColors.fallbackColor;
  return systemColor;
};

// TODO: very hacky and unpleasing, must be changed
const getCircle = (expRatioMatch, isSmallScreen) => {
  const style = isSmallScreen
    ? {}
    : {
        position: "relative",
        top: "0.65em",
      };
  const value = !expRatioMatch ? null : +expRatioMatch[1];
  if (isFinite(value) && value !== null) {
    // fallback should remain red
    style.background =
      2 <= value
        ? "green"
        : 0 <= value
        ? "#E1FF47"
        : -2 < value
        ? "#e09926"
        : "red";
  }
  return <Circle style={style} />;
};

const SubsystemPanel = (props) => {
  const {
    t,
    lastUpdate,
    info,
    choosenViewFormat,
    sysMessages,
    isSubscribed,
    getIdToken,
    status: { statusDaily, statusMonthly, statusAnnual },
  } = props;
  const panelColor = systemBackgroundColor(statusDaily, false, false);
  let SelectedElementToRender = null;
  let elementProps = null;
  switch (choosenViewFormat) {
    case 1:
      SelectedElementToRender = SystemDailyStatus;
      elementProps = {
        sysStatus: statusDaily,
        sysInfo: info,
        isSubscribed: isSubscribed,
        userRole: props.userRole,
        loadDailyInsights: props.loadDailyInsights,
        preferences: props.preferences,
        intactnessColor: panelColor.backgroundColor,
      };
      break;
    case 2:
      SelectedElementToRender = SystemMonthlyStatus;
      elementProps = {
        sysStatus: statusMonthly,
        sysMessages: sysMessages,
        sysInfo: info,
        userRole: props.userRole,
        getIdToken: getIdToken,
        userName: props.userName,
        loadMonthlyInsights: props.loadMonthlyInsights,
      };
      break;
    case 3:
      SelectedElementToRender = SystemSingleAnnualStatus;
      elementProps = { sysStatus: statusAnnual, sysInfo: info };
      break;
    default:
      SelectedElementToRender = SystemAnnualStatus;
      elementProps = { sysStatus: statusAnnual, sysInfo: info };
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>{info.name}</h2>
      <UpdatedAndMakeComponent
        t={t}
        BaseContainer={UpdateDateContainerSubsystem}
        info={info}
        lastUpdate={lastUpdate}
      />
      <SelectedElementToRender {...elementProps} />
    </div>
  );
};

const UpdatedAndMakeComponent = (props) => {
  const { t, BaseContainer, info, lastUpdate } = props;
  return (
    <BaseContainer>
      <SystemTypeSpan info={info}>
        {t("System type")}: {info.make || "-"}
      </SystemTypeSpan>
      <span style={{ float: "end" }}>
        {t("Updated")}: {lastUpdate}
      </span>
    </BaseContainer>
  );
};

const SystemStatus = (props) => {
  const [choosenViewFormat, changeViewFormat] = useState(1);
  const [windowWidth] = useState(window.innerWidth);
  const { t, subsystems, yearMessages, subsystemsStatus } = props;
  const updateDateContainerStyle = {
    padding: "15px 15px 0 15px",
    fontSize: "18px",
    textAlign: "end",
  };
  const panelTitle = (
    <PTitle>
      {props.name} {props.isDemo && ` (${t("Demo Sys")})`}
    </PTitle>
  );
  const sysPower = props.info.power ? +props.info.power : "N/A";
  const sysHealth = props.status.syshealthpct ? +props.status.syshealthpct : "";
  const sysYield = props.status.denoutput
    ? Math.round(+props.status.denoutput * 10) / 10
    : "";

  if (props.isFetching) {
    return (
      <Panel>
        <Panel.Heading>{t("Loading")}</Panel.Heading>
      </Panel>
    );
  }

  if (!props.status) {
    return (
      <Panel eventKey={props.index}>
        <Panel.Heading>
          <Panel.Title toggle>
            <Row>
              <Col xs={12}>{panelTitle}</Col>
            </Row>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>{t("No status data found")}</Panel.Body>
      </Panel>
    );
  }

  // could be undefined or the wanted value, should be used to calculate the color of the circle
  const possibleRatios = Object.entries(props.statusMonthly).reduce(
    (res, [key, val]) => {
      const [match, logTime, fieldName] = /(\d{6})-(\w+)/g.exec(key) || [];
      if (match) {
        if (logTime < res.maxLogTime) {
          return res;
        }
        res.maxLogTime = logTime;
        if (val.length && fieldName === "expratio") {
          res.match = { logTime, result: [key, val] };
        }
      }
      return res;
    },
    { maxLogTime: "", match: { logTime: "", result: null } }
  );

  const expectedRatio =
    possibleRatios.maxLogTime === possibleRatios.match.logTime
      ? possibleRatios.match.result
      : null;

  const lastUpdate = props.lastSysUpdated;
  const gradientDir = props.i18n.dir() === "rtl" ? "left" : "right";
  const marginDir = gradientDir === "left" ? "right" : "left";
  const panelColor = systemBackgroundColor(
    props.status,
    props.info.isParentSys,
    props.info.frozen
  );

  return (
    // <Panel id={`${props.info.id}-sysId`} expanded={props.isOpenedCard} onToggle={() => props.toggleSystem(props.index)}>
    <StyledPanel
      id={`${props.info.id}-sysId`}
      eventKey={props.index}
      border={panelColor.backgroundColor}
      direction={gradientDir}
    >
      <PanelHeading
        dir={gradientDir}
        percentage={
          windowWidth < 767
            ? panelHeadingPercentage.small
            : panelHeadingPercentage.large
        }
        backgroundcolor={panelColor.backgroundColor}
      >
        <Panel.Title toggle>
          {windowWidth < 767 ? (
            <HeadingRowSmallScreen dir={marginDir}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "-10px",
                  marginRight: "-10px",
                }}
              >
                <Col md={4} sm={4} xs={6}>
                  <BigText>{panelTitle}</BigText>
                  {getCircle(expectedRatio, true)}
                </Col>
                <ColTextEnd md={2} sm={2} xs={6}>
                  <NoBaldBigText>{sysPower}</NoBaldBigText>kW
                </ColTextEnd>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "-10px",
                  marginRight: "-10px",
                }}
              >
                <Col md={3} sm={3} xs={6}>
                  {t(INTACTNESS_KEY)} <BigText> {sysHealth}</BigText>%
                </Col>
                <ColTextEnd md={3} sm={3} xs={6}>
                  {t("Yield")}
                  <BigText> {sysYield}</BigText>kWh
                </ColTextEnd>
              </Row>
            </HeadingRowSmallScreen>
          ) : (
            <Row>
              <Col md={3} sm={3} xs={6}>
                <BigText>{panelTitle}</BigText>
              </Col>
              <Col md={2} sm={2} xs={6}>
                <NoBaldBigText>{sysPower}</NoBaldBigText>kW
              </Col>
              <Col md={3} sm={3} xs={6}>
                {t(INTACTNESS_KEY)}
                <BigText> {sysHealth}</BigText>%
              </Col>
              <Col md={3} sm={3} xs={6}>
                {t("Yield")}
                <BigText> {sysYield}</BigText>kWh
              </Col>
              <Col md={1} sm={1} xs={1}>
                {getCircle(expectedRatio, false)}
              </Col>
            </Row>
          )}
        </Panel.Title>
      </PanelHeading>
      <Panel.Collapse>
        {!props.isSelected ? null : (
          <Panel.Body style={{ padding: "15px 0" }}>
            <Tabs
              defaultActiveKey={choosenViewFormat}
              id="uncontrolled-tab-example"
              onSelect={(eventKey) => changeViewFormat(eventKey)}
            >
              <STab
                eventKey={1}
                style={props.isParentSystem ? updateDateContainerStyle : {}}
                title={t("Daily")}
              >
                {!props.isParentSystem && (
                  <div>
                    <UpdatedAndMakeComponent
                      t={t}
                      BaseContainer={UpdateDateContainer}
                      info={props.info}
                      lastUpdate={lastUpdate}
                    />
                    <SystemDailyStatus
                      sysStatus={props.status}
                      sysInfo={props.info}
                      isSubscribed={props.isSubscribed}
                      userRole={props.userRole}
                      loadDailyInsights={props.loadDailyInsights}
                      intactnessColor={panelColor.backgroundColor}
                      preferences={props.preferences}
                    />
                  </div>
                )}
              </STab>
              <STab
                eventKey={2}
                style={props.isParentSystem ? updateDateContainerStyle : {}}
                title={t("Monthly")}
              >
                {!props.isParentSystem && (
                  <div>
                    <UpdatedAndMakeComponent
                      t={t}
                      BaseContainer={UpdateDateContainer}
                      info={props.info}
                      lastUpdate={lastUpdate}
                    />
                    <SystemMonthlyStatus
                      sysInfo={props.info}
                      sysMessages={yearMessages[props.info.id]}
                      sysStatus={props.statusMonthly}
                      userRole={props.userRole}
                      getIdToken={props.getIdToken}
                      userName={props.userName}
                      loadMonthlyInsights={props.loadMonthlyInsights}
                    />
                  </div>
                )}
              </STab>
              <STab
                eventKey={3}
                style={props.isParentSystem ? updateDateContainerStyle : {}}
                title={t("Annual")}
              >
                {!props.isParentSystem && (
                  <div>
                    <UpdatedAndMakeComponent
                      t={t}
                      BaseContainer={UpdateDateContainer}
                      info={props.info}
                      lastUpdate={lastUpdate}
                    />
                    <SystemSingleAnnualStatus
                      sysStatus={props.statusAnnual}
                      sysInfo={props.info}
                    />
                  </div>
                )}
              </STab>
              <STab
                eventKey={4}
                style={props.isParentSystem ? updateDateContainerStyle : {}}
                title={t("Total")}
              >
                {!props.isParentSystem && (
                  <div>
                    <UpdatedAndMakeComponent
                      t={t}
                      BaseContainer={UpdateDateContainer}
                      info={props.info}
                      lastUpdate={lastUpdate}
                    />
                    <SystemAnnualStatus
                      sysStatus={props.statusAnnual}
                      sysInfo={props.info}
                    />
                  </div>
                )}
              </STab>
            </Tabs>
            {subsystems.length
              ? subsystems.map((subsystem, subIndex) => (
                  <div
                    key={`${subsystem.id}-subsystem`}
                    id={`${subsystem.id}-sysId`}
                  >
                    {subIndex !== 0 && <hr />}
                    <SubsystemPanel
                      t={t}
                      lastUpdate={lastUpdate}
                      info={subsystem}
                      status={subsystemsStatus[subIndex]}
                      sysMessages={yearMessages[subsystem.id]}
                      isSubscribed={props.isSubscribed}
                      choosenViewFormat={choosenViewFormat}
                      userRole={props.userRole}
                      loadDailyInsights={props.loadDailyInsights}
                      getIdToken={props.getIdToken}
                      userName={props.userName}
                      preferences={props.preferences}
                      loadMonthlyInsights={props.loadMonthlyInsights}
                    />
                  </div>
                ))
              : null}
          </Panel.Body>
        )}
      </Panel.Collapse>
    </StyledPanel>
  );
};

SystemStatus.propTypes = {
  name: PropTypes.string, // system display name
  updated: PropTypes.string, // when was the status updated
  status: PropTypes.object, // status data to display
  subsystems: PropTypes.array, // system subsystems
  statusAnnual: PropTypes.array, // annual status data to display
  info: PropTypes.object, // static system info
  isParentSystem: PropTypes.bool,
  statusMonthly: PropTypes.object,
  isDemo: PropTypes.bool,
  isOpenedCard: PropTypes.bool,
  yearMessages: PropTypes.object,
  index: PropTypes.number,
  // toggleSystem: PropTypes.func,
  isSubscribed: PropTypes.bool,
  isFetching: PropTypes.bool,
  userRole: PropTypes.object,
  loadDailyInsights: PropTypes.func,
  subsystemsStatus: PropTypes.array,
  lastSysUpdated: PropTypes.string,
};
SystemStatus.defaultProps = {
  name: "NA",
  updated: Date.now().toString(),
  status: null,
  statusAnnual: [],
  statusMonthly: null,
  subsystems: [],
  yearMessages: null,
  info: null,
  isParentSystem: false,
  isDemo: false,
  isSubscribed: false,
  isFetching: true,
  lastSysUpdated: "",
  // toggleSystem: () => { return }
};

export default withTranslation()(SystemStatus);
